<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loading">
                <b-row v-if="approvals.length > 0">
                    <b-col lg="12" sm="12" class="text-center mb-4">
                        <h5 class='complain-title'>{{ $t('pump_install.send') + ' ' + $t('pump_install.notel') }}</h5>
                    </b-col>
                    <b-col lg="12" sm="12">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <div>
                                    <b-table-simple striped bordered small>
                                        <b-tr>
                                            <b-th>{{ $t('user.namel') }}</b-th>
                                            <b-th>{{ $t('pump_install.send_for') }}</b-th>
                                            <b-th>{{ $t('pump_install.notel') }}</b-th>
                                        </b-tr>
                                        <b-tr v-for="(approval,index) in approvals" :key="index">
                                            <b-td>{{ getUserName(approval.sender_id) }}</b-td>
                                            <b-td>{{ (approval.for === 1) ? $t('pump_install.check') : $t('pump_install.survey') }}</b-td>
                                            <b-td>{{ ($i18n == 'bn')? approval.note_bn : approval.note }}</b-td>
                                        </b-tr>
                                    </b-table-simple>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row style="margin-top:20px;" v-if="surveys.length > 0">
                    <b-col lg="12" sm="12" class="text-center mb-4">
                        <h5 class='complain-title'>{{ $t('pump_install.survey') + ' ' + $t('pump_install.notel') }}</h5>
                    </b-col>
                    <b-col lg="12" sm="12">
                        <b-row>
                            <b-col lg="12" sm="12">
                                <div>
                                    <b-table-simple striped bordered small>
                                        <b-tr>
                                            <b-th>{{ $t('user.namel') }}</b-th>
                                            <b-th>{{ $t('pump_install.survey_date') }}</b-th>
                                            <b-th>{{ $t('pump_install.notel') }}</b-th>
                                        </b-tr>
                                        <b-tr v-for="(survey,index) in surveys" :key="index">
                                            <b-td>{{ getUserName(survey.user_id) }}</b-td>
                                            <b-td>{{ survey.survey_date }}</b-td>
                                            <b-td>{{ ($i18n == 'bn')? survey.note_bn : survey.note }}</b-td>
                                        </b-tr>
                                    </b-table-simple>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpOptAppSendSurveyNote } from '../../api/routes'

export default {
  name: 'SurveySendNote',
  props: ['id'],
  created () {
    this.loadData()
  },
  data () {
    return {
      approvals: [],
      surveys: [],
      users: []
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      await RestApi.getData(irriSchemeServiceBaseUrl, pumpOptAppSendSurveyNote + '/' + this.$props.id).then(response => {
        if (response.success) {
          this.approvals = response.approvals.length > 0 ? response.approvals : []
          this.surveys = response.surveys.length > 0 ? response.surveys : []
          this.users = response.users.data
        }
        this.loading = false
      })
    },
    getUserName (userId) {
      const user = this.users.find(usr => usr.value === userId)
      if (this.$i18n.locale === 'bn') {
        return user !== undefined ? user.text_bn : ''
      } else {
        return user !== undefined ? user.text_en : ''
      }
    }
  }
}
</script>
