<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('pump_install.pump_operator_application') }} </h4>
      </template>
      <template v-slot:body>
        <form-search :data="searchAttributes" @returned-data="searchData" @change="setData"/>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pump_install.pump_operator_new_application') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="applications" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(status)="data">
                     <span class="badge">{{ getStatus(data.item.status) }}</span>
                    </template>
                    <template v-slot:cell(address)="data">
                     {{ getAddress(data.item) }}
                    </template>
                    <template v-slot:cell(pump_address)="data">
                     {{ getPumpAddress(data.item) }}
                    </template>
                    <template v-slot:cell(created_date)="data">
                      <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFormat}}</span>
                    </template>
                    <template v-slot:cell(payment_status)="data">
                     <span class="badge">{{ getPaymentStatus(data.item.payment_status) }}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-if="$store.state.Auth.authUser.ssoLogin && (data.item.status !== 9 || data.item.status !== 3)" style="font-size: 12px !important;" class="mr-1" variant="btn btn-xs btn-success" size="sm" @click="sendToNothi(data.item)" ><i class="fa fa-forward"></i> {{$t('globalTrans.to_nothi')}}</b-button>
                      <a href="javascript:" class="btn_table_action table_action_edit" v-if="data.item.status === 2 || data.item.status !== 5 && data.item.status !== 6 && data.item.status !== 3" v-b-modal.modal-2 title="Send"  @click="setRowId(data.item)"><i class="ri-arrow-up-line m-0"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_status" v-if="data.item.status === 2 || data.item.status !== 5 && data.item.status !== 6 && data.item.status !== 3 && data.item.status !== 8" v-b-modal.modal-3 title="Send For Survey" @click="setRowId(data.item)"><i class="ri-arrow-right-line"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_view" v-b-modal.modal-7 title="View Application"  @click="setRowId(data.item)"><i class="fas fa-eye"></i></a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" v-if="data.item.status >= 5" v-b-modal.modal-4 title="Send & Survey Note" @click="setRowId(data.item)"><i class="ri-file-chart-line"></i></a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="testClick"
                  />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
        <b-modal id="modal-7" size="lg" :title="$t('pump_install.application_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <p>
            <PumpOptApplicationDetails :id="testId" :key="testId"/>
          </p>
        </b-modal>
        <b-modal id="modal-2" size="lg" :title="$t('pump_install.send')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <p>
            <Send :id="testId" :key="testId"/>
          </p>
        </b-modal>
        <b-modal id="modal-3" size="lg" :title="$t('pump_install.send_for_survey')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <p>
            <SendForSurvey :id="testId" :key="testId"/>
          </p>
        </b-modal>
        <b-modal id="modal-4" size="lg" :title="$t('pump_install.send_survey_note')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <p>
            <SurveySendNote :id="testId" :key="testId"/>
          </p>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpOptApplicationList, pumpOptApplicationApprove, pumpOptNothiSend } from '../../api/routes'
import PumpOptApplicationDetails from '../PumpOptApplicationDetails'
import Send from './Send'
import SendForSurvey from './SendForSurvey'
import SurveySendNote from './SurveySendNote'
import FormSearch from '@/components/structure/form/search/FormSearch'
import { Address } from '@/mixins/helper-functions'
import { mapGetters } from 'vuex'
import Store from '@/store'

export default {
  mixins: [Address],
  name: 'UiDataTable',
  components: {
    PumpOptApplicationDetails,
    Send,
    SendForSurvey,
    SurveySendNote,
    FormSearch
  },
  data () {
    return {
      statePrefix: 'commonObj', // Required to get store sates for addresses
      applications: [],
      loggedInUserId: this.$store.state.Auth.authUser.user_id,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
          mouza_no: '',
          plot_no: '',
          jl_no: '',
          org_id: 0,
          far_division_id: 0,
          far_district_id: 0,
          far_upazilla_id: 0,
          far_union_id: 0
      },
      testId: 0,
      rows: [],
      ifApply: false,
      irriUrl: irriSchemeServiceBaseUrl
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    searchAttributes () {
        return {
            buttonText: this.$t('globalTrans.search'),
            data: [
                {
                    name: 'org_id',
                    type: 'Select',
                    label: 'pump_install.organization',
                    options: this.orgList,
                    value: this.search.org_id
                },
                {
                    name: 'far_division_id',
                    type: 'Select',
                    label: 'org_pro_division.division',
                    options: this.divisionList,
                    value: this.search.far_division_id
                },
                {
                    name: 'far_district_id',
                    type: 'Select',
                    label: 'org_pro_district.district',
                    options: this.districtList,
                    value: this.search.far_district_id
                },
                {
                    name: 'far_upazilla_id',
                    type: 'Select',
                    label: 'org_pro_upazilla.upazilla',
                    options: this.upazilaList,
                    value: this.search.far_upazilla_id
                },
                {
                    name: 'far_union_id',
                    type: 'Select',
                    label: 'org_pro_union.union',
                    options: this.unionList
                },
                {
                    name: 'mouza_no',
                    type: 'Input',
                    label: 'pump_install.mauza_no',
                    labelCol: 3
                },
                {
                    name: 'plot_no',
                    type: 'Input',
                    label: 'pump_install.plot_no',
                    labelCol: 3
                },
                {
                    name: 'jl_no',
                    type: 'Input',
                    label: 'pump_install.pump_jl_no',
                    labelCol: 3
                }
            ]
        }
    },
    formTitle () {
       return (this.testId === 0) ? this.$t('org_pro.office') + ' ' + this.$t('globalTrans.entry') : this.$t('org_pro.office') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.ExternalUserIrrigation.pumpOptApplicationLists
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('pump_install.name'), class: 'text-center' },
          { label: this.$t('externalUserIrrigation.address'), class: 'text-center' },
          { label: this.$t('externalUserIrrigation.pump_address'), class: 'text-center' },
          { label: this.$t('externalUserIrrigation.payment_status'), class: 'text-center' },
          { label: this.$t('pump_install.created_date'), class: 'text-center' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
        { key: 'index' },
          { key: 'name_bn' },
          { key: 'address' },
          { key: 'pump_address' },
          { key: 'payment_status' },
          { key: 'created_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
         { key: 'index' },
          { key: 'name' },
          { key: 'address' },
          { key: 'pump_address' },
          { key: 'payment_status' },
          { key: 'created_date' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
  watch: {
      'search.far_division_id': function (newVal, oldVal) {
          if (newVal !== oldVal) {
            this.districtList = this.getDistrictList(newVal)
          }
      },
      'search.far_district_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.upazilaList = this.getUpazilaList(newVal)
        }
      },
      'search.far_upazilla_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.unionList = this.getUnionList(newVal)
        }
      },
      currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            if (this.search.far_division_id) {
              this.districtList = this.getDistrictList()
            }
            if (this.search.far_district_id) {
              this.upazilaList = this.getUpazilaList()
            }
            if (this.search.far_upazilla_id) {
              this.unionList = this.getUnionList()
            }
        }
    },
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        far_division_id: this.authUser.office_detail.division_id,
        far_district_id: this.authUser.office_detail.district_id,
        far_upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
    this.loadData()
  },
  methods: {
    setData (item) {
      Object.assign(this.search, item)
    },
    resetId () {
      this.testId = 0
    },
    testClick () {
      this.loadData()
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    async searchData (payload) {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          far_division_id: this.authUser.office_detail.division_id,
          far_district_id: this.authUser.office_detail.district_id,
          far_upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      this.search = payload
      await this.loadData()
    },
    setRowId (item) {
      this.testId = item.id
    },
    approve (item) {
      this.$swal({
        title: this.$t('globalTrans.statusChangeMsg'),
        showCancelButton: true,
        confirmButtonText: this.$t('globalTrans.yes'),
        cancelButtonText: this.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.toggleStatus(item)
        }
      })
    },
    async toggleStatus (item) {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      result = await RestApi.deleteData(irriSchemeServiceBaseUrl, `${pumpOptApplicationApprove}/${item.id}`).then(response => {
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
        if (result.success) {
          // push notification
          // const notification = result.notification
          // this.$socket.emit('send-notification', notification)

          this.$store.dispatch('Complain/updateComplainChainStatus', item)
          this.$toast.success({
            title: 'Success',
            message: this.$t('globalTrans.update_msg'),
            color: '#D6E09B'
          })
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
      })
    },
    async loadData () {
      const status = this.$route.query.status
      let searchData = ''
      if (status !== undefined) {
        searchData = Object.assign({}, this.search, { status: status })
      } else {
        searchData = this.search
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, searchData, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      await RestApi.getData(irriSchemeServiceBaseUrl, pumpOptApplicationList, params)
      .then(response => {
        if (response.success) {
          this.applications = this.getRelationalData(response.data.data)
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const divisionList = this.$store.state.commonObj.divisionList
      const districtList = this.$store.state.commonObj.districtList
      const upazilaList = this.$store.state.commonObj.upazilaList
      const unionList = this.$store.state.commonObj.unionList
      const orgList = this.$store.state.orgList

      const datas = data.map(item => {
        const farDivision = divisionList.find(div => div.value === item.far_division_id)
        const farDistrict = districtList.find(dist => dist.value === item.far_district_id)
        const farUpazilla = upazilaList.find(upz => upz.value === item.far_upazilla_id)
        const farUnion = unionList.find(un => un.value === item.far_union_id)
        const org = orgList.find(org => org.value === item.org_id)

        const farAddress = {
          far_division_name: typeof farDivision !== 'undefined' ? farDivision.text_en : '',
          far_division_name_bn: typeof farDivision !== 'undefined' ? farDivision.text_bn : '',
          far_district_name: typeof farDistrict !== 'undefined' ? farDistrict.text_en : '',
          far_district_name_bn: typeof farDistrict !== 'undefined' ? farDistrict.text_bn : '',
          far_upazilla_name: typeof farUpazilla !== 'undefined' ? farUpazilla.text_en : '',
          far_upazilla_name_bn: typeof farUpazilla !== 'undefined' ? farUpazilla.text_bn : '',
          far_union_name: typeof farUnion !== 'undefined' ? farUnion.text_en : '',
          far_union_name_bn: typeof farUnion !== 'undefined' ? farUnion.text_bn : '',
          org_name: typeof org !== 'undefined' ? org.text_en : '',
          org_name_bn: typeof org !== 'undefined' ? org.text_bn : ''
        }
        return Object.assign(item, farAddress)
      })
      return datas
    },
    getPaymentStatus (payStatus) {
      if (payStatus === 0) {
        return this.$t('externalUserIrrigation.unpaid')
      } else {
        return this.$t('pump_install.paid')
      }
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('pump_install.pending')
      } else if (status === 2) {
        return this.$t('pump_install.processing')
      } else if (status === 3) {
        return this.$t('pump_install.approve')
      } else if (status === 4) {
        return this.$t('pump_install.reject')
      } else if (status === 4) {
        return this.$t('pump_install.pending')
      } else if (status === 5) {
        return this.$t('pump_install.send')
      } else if (status === 6) {
        return this.$t('pump_install.send_for_survey')
      } else if (status === 7) {
        return this.$t('pump_install.receive')
      } else if (status === 8) {
        return this.$t('pump_install.survey_submitted')
      } else if (status === 9) {
        return this.$t('pump_install.forwarded_to_nothi')
      }
    },
    getAddress (item) {
      const address = []
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.far_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.far_village_bn !== undefined ? item.far_village_bn : '', tmpUnion ? tmpUnion.text_bn : '')
      } else {
        address.push(item.far_village !== undefined ? item.far_village : '', tmpUnion ? tmpUnion.text_en : '')
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.far_upazilla_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.far_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.far_division_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    },
    getPumpAddress (item) {
      const address = []
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.pump_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.pump_mauza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion.text_bn)
      } else {
        address.push(item.pump_mauza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion.text_en)
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.pump_upazilla_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.pump_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.pump_division_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    },
    sendToNothi (item) {
      this.toggleStatusCustom2(irriSchemeServiceBaseUrl, pumpOptNothiSend, item)
    },
    toggleStatusCustom2 (baseUrl, uri, item) {
      window.vm.$swal({
        title: window.vm.$t('elearning_venue.are_you_sure_forward_to_nothi'),
        showCancelButton: true,
        confirmButtonText: window.vm.$t('globalTrans.yes'),
        cancelButtonText: window.vm.$t('globalTrans.no'),
        focusConfirm: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.changeStatusCustom(baseUrl, uri, item)
        }
      })
    },
    changeStatusCustom (baseUrl, uri, item) {
      Store.dispatch('mutateCommonProperties', { loading: true })
      RestApi.postData(baseUrl, uri, item).then(response => {
        if (response.success) {
          window.vm.$toast.success({
            title: 'Success',
            message: 'Data Updated Successfully',
            color: '#D6E09B'
          })
        } else {
          window.vm.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        Store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    }
  }
}
</script>
